import NotificationBadge from "@/components/layout/Sidebar/NotificationBadge"
import { isDisabledMode } from "@/helpers/price-calendar"
import { isSharedInventoryEnabled, isNicolasEnabled } from "@/helpers/organization"
import { isEnvironment } from "@/helpers/common"

// TODO: refactor routes/links to have one source of truth

const inventoryManagementPage = () => {
  if (!isSharedInventoryEnabled()) {
    return {
      title: "components.sidebar.inventory_management",
      dataCy: "inventory",
      icon: "windows",
      opened: true,
      nested: [
        {
          permissionType: "inventory_management",
          title: "components.sidebar.inventory_management",
          page: "InventoryManagement"
        },
        {
          title: "components.sidebar.sold_out",
          page: "SoldOut",
          permissionType: "inventory_management",
          hidden: isNicolasEnabled()
        },
        {
          permissionType: "inventory_management",
          title: "components.sidebar.sale_stop_settings",
          page: "SaleStopSettings",
          hidden: isNicolasEnabled()
        }
      ]
    }
  }

  return {
    title: "components.sidebar.shared_inventory_management",
    dataCy: "inventory",
    icon: "windows",
    opened: true,
    nested: [
      {
        title: "components.sidebar.shared_inventory_management",
        page: "SharedInventoryManagement",
        permissionType: "inventory_management"
      },
      {
        title: "components.sidebar.sold_out",
        page: "SoldOut",
        permissionType: "inventory_management"
      },
      {
        title: "components.sidebar.sale_stop_settings",
        page: "SaleStopSettings",
        permissionType: "inventory_management"
      }
    ]
  }
}

export default () => [
  inventoryManagementPage(),
  {
    title: "components.sidebar.price_management",
    dataCy: "price-management",
    icon: "carSolid",
    opened: true,
    shouldRender: () => !isDisabledMode(),
    nested: [
      {
        title: "components.sidebar.price_calendar",
        page: "PriceCalendar",
        permissionType: "price_calendar"
      },
      {
        title: "components.sidebar.general_settings",
        page: "GeneralSettings",
        permissionType: "competitors_settings"
        // subPages: ["ModeSettings", "CompetitorsSettings", "RuleSettings"]
      },
      {
        title: "components.sidebar.plan_settings",
        page: "PlanSettings",
        permissionType: "plan_settings",
        // uses for nested routes.
        // need to mark link as active if current route is a sub page
        subPages: ["BasicSettings", "ByPeriod"]
      },
      {
        title: "components.sidebar.competitor_prices",
        page: "CompetitorPrices",
        permissionType: "competitor_prices"
      }
    ],
    hideFromSuperadmin: true
  },
  {
    title: "components.sidebar.plan_management",
    dataCy: "plan-management",
    icon: "gem",
    opened: true,
    hideFromSuperadmin: true,
    nested: [
      {
        title: "components.sidebar.plan_list",
        page: "PlanList",
        permissionType: "plan_settings",
        hidden: isEnvironment("production")
      },
      {
        title: "components.sidebar.base_rate_period_settings",
        page: "BaseRatePeriodSettings",
        permissionType: "plan_settings",
        hidden: isEnvironment("production")
      }
    ]
  },
  {
    title: "components.sidebar.company_system",
    dataCy: "company-system",
    icon: "bookingSystem",
    opened: true,
    hideFromSuperadmin: true,
    nested: [
      {
        title: "components.sidebar.booking_confirmation",
        page: "CompanySystem/BookingConfirmation",
        permissionType: "company_system"
      },
      {
        title: "components.sidebar.rate_plan",
        page: "CompanySystem/RatePlanSettings",
        permissionType: "company_system",
        subPages: ["RatePlan", "PriceTable"]
      },
      {
        title: "components.sidebar.car_class_settings",
        page: "CompanySystem/CarClassSettings",
        permissionType: "company_system",
        subPages: ["CarClass", "CarModel"]
      },
      {
        title: "components.sidebar.shop_settings",
        page: "CompanySystem/ShopsSettings",
        permissionType: "company_system",
        subPages: ["Shops", "InventoryGroups"]
      },
      {
        title: "components.sidebar.basic_settings",
        page: "CompanySystem/BasicSettings",
        permissionType: "company_system",
        subPages: ["TimeDivision", "Options", "DropOffFees", "CancellationFees", "About"]
      }
    ]
  },
  {
    title: "components.sidebar.revenue_analysis",
    page: "RevenueAnalysis",
    icon: "chartBar",
    shouldRender: isEnvironment("staging") && !isNicolasEnabled(),
    hideFromSuperadmin: true
  },
  // {
  //   title: "components.sidebar.for_head_office",
  //   page: "NotFound",
  //   icon: "building",
  //   permissionType: "for_head_office"
  // },
  // {
  //   title: "components.sidebar.reservations",
  //   page: "Reservations",
  //   icon: "calendarCheck",
  //   permissionType: "reservations"
  // },
  // {
  //   title: "components.sidebar.income",
  //   page: "NotFound",
  //   icon: "wallet",
  //   permissionType: "price_management"
  // },
  {
    title: "components.sidebar.settings",
    icon: "cog",
    dataCy: "settings",
    opened: true,
    hideFromSuperadmin: true,
    nested: [
      {
        title: "components.sidebar.ota_setting",
        page: "OtaAccounts",
        permissionType: "ota_setting"
      },
      {
        title: "components.sidebar.shop_matching",
        page: "ShopMatching",
        permissionType: "shop_matching"
      },
      {
        title: "components.sidebar.car_class_matching",
        page: "CarClassMatching",
        permissionType: "car_class_matching",
        hidden: isNicolasEnabled()
      },
      {
        title: "components.sidebar.shops_sources_car_matching",
        page: "ShopsSourcesCarMatching",
        permissionType: "shops_sources_car_matching",
        hidden: !isNicolasEnabled()
      },
      {
        title: "components.sidebar.shops_car_class_matching",
        page: "ShopsCarClassMatching",
        permissionType: "shops_car_class_matching",
        hidden: isSharedInventoryEnabled() || isNicolasEnabled()
      },
      {
        title: "components.sidebar.inventory_group_matching",
        page: "InventoryGroupMatching",
        permissionType: "inventory_group_matching",
        hidden: !isSharedInventoryEnabled()
      },
      {
        title: "components.sidebar.inventory_group_car_class_matching",
        page: "InventoryGroupsCarClassMatching",
        permissionType: "inventory_group_car_class_matching",
        hidden: !isSharedInventoryEnabled()
      },
      {
        title: "components.sidebar.inventory_notification_settings",
        page: "InventoryNotificationSettings",
        permissionType: "inventory_notification_settings",
        hidden: isEnvironment("production")
      }
    ]
  },
  {
    title: "components.sidebar.notifications",
    page: "Notifications",
    icon: "bell",
    permissionType: "notifications",
    component: NotificationBadge
  },
  {
    title: "components.sidebar.logs",
    page: "Logs",
    icon: "logs",
    permissionType: "logs",
    subPages: ["UserActionLog", "InventoryLog"]
  },
  {
    title: "components.sidebar.account_management",
    icon: "user",
    dataCy: "account-management",
    opened: true,
    hideFromSuperadmin: true,
    nested: [
      {
        title: "components.sidebar.account_list",
        page: "AccountList",
        permissionType: "account_list"
      },
      {
        title: "components.sidebar.permission_settings",
        page: "PermissionSettings",
        permissionType: "permission_settings"
      },
      {
        title: "components.sidebar.create_account",
        page: "CreateAccount",
        permissionType: "create_account"
      }
    ]
  }
]
