// Maestro links means "for superadmin"
// maestro === superadmin

import { isEnvironment } from "@/helpers/common"

export default () => [
  {
    title: "components.sidebar.account_list",
    icon: "user",
    page: "Maestro/AccountList",
    subPages: ["CarRental", "OtaAdmins"]
  },
  {
    title: "components.sidebar.company_list",
    icon: "building",
    page: "Maestro/Markups/CompanyList"
  },
  {
    title: "components.sidebar.plan_list",
    icon: "gem",
    page: "Maestro/Markups/PlanList"
  },
  {
    title: "components.sidebar.organization_list",
    icon: "sitemap",
    page: "Maestro/OrganizationList"
  },
  {
    title: "components.sidebar.organizations_admin_list",
    icon: "organizationAdmin",
    page: "Maestro/OrganizationsAdminList"
  },
  {
    title: "components.sidebar.translations",
    icon: "globe",
    page: "Maestro/Translations",
    shouldRender: !isEnvironment("production")
  },
  {
    title: "components.sidebar.config_items_list",
    icon: "setting",
    page: "Maestro/ConfigItemsList",
    shouldRender: !isEnvironment("production")
  }
]
